import { api, apiLogin } from '@/api';

export const loginRequest = async data => {
  const response = await apiLogin.post(
    `${process.env.REACT_APP_HOST_AUTH}/authentication/login`,
    data,
  );
  return response.data;
};

export const getSendPasswordEmail = async data => {
  const response = await apiLogin.post(
    `${process.env.REACT_APP_HOST_AUTH}/authentication/forgot-password`,
    data,
  );
  return response.data;
};

export const getResetPassword = async data => {
  const response = await apiLogin.put(
    `${process.env.REACT_APP_HOST_AUTH}/authentication/change-password`,
    data,
  );
  return response.data;
};

export const getCouponDataRequest = async ({
  couponNumber,
  series,
  subsidiary,
  codeBank,
}) => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/TrocoDigital/cupom?cupom=${couponNumber}&serie=${series}&filial=${subsidiary}&bank=${codeBank}`,
  );

  if (response.status === 202) {
    throw new Error(
      `Não foram encontrados troco digital para o banco ${codeBank}`,
    );
  }

  if (response.status !== 200) {
    throw new Error('Cupom não encontrado');
  }

  return response.data;
};

export const getAllChequeRequest = async query => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/TrocoDigital/list-schedules?${query}`,
  );

  if (response.status !== 200) {
    throw new Error('Não foi possível listar os cheques troco');
  }

  return response.data;
};

export const getAccountOwner = async queryParams => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/accountowners?${queryParams}`,
  );
  if (response.status !== 200) {
    throw new Error(`Favorecido não encontrado`);
  }

  return response.data;
};

export const getAllCnabRequest = async cnpj => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/Cnab/get-all?cnpj=${cnpj}`,
  );

  if (response.status !== 200) {
    throw new Error('Não foi possivel listar dados cnab');
  }

  const { listCnab } = response.data;
  return listCnab;
};

export const getAllCnabResposeRequest = async cnpj => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/Cnab/get-respose?cnpj=${cnpj}`,
  );

  if (response.status !== 200) {
    throw new Error('Não foi possivel listar dados cnab');
  }

  const { listCnab } = response.data;
  return listCnab;
};

export const postAprovarCnabRequest = async ({ idCnab, userId }) => {
  const response = await api.post(
    `${process.env.REACT_APP_HOST_POSTO}/Cnab/authorize-send-file?idCnab=${idCnab}&userId=${userId}`,
  );

  if (response.status === 204) {
    throw new Error(`${response}`);
  }

  if (response.status === 202) {
    throw new Error(`${response}`);
  }

  if (response.status !== 200) {
    throw new Error(`${response}`);
  }

  return response;
};

export const getDownloadSheet = async values => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/Cnab/download?id=${values}`,
    {
      responseType: 'blob',
    },
  );
  return response.data;
};

export const getDepositByRaAndNumberAccount = async queryParams => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/DepositFavored/code-ra-protheus?${queryParams}`,
  );

  if (response.status === 202) {
    return response.data;
  }

  if (response.status !== 200) {
    throw new Error('Dados da requisição não encontrado');
  }

  const { raConfirmed } = response;
  if (raConfirmed === true) {
    throw new Error('RA já com depósito efetivado!');
  }

  return response.data;
};

export const getDepositoRequest = async codeRa => {
  const codeRaParams = `codeRa=${codeRa}`;

  return getDepositByRaAndNumberAccount(codeRaParams);
};

export const getWithdrawReports = async searchQuery => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/Withdraw?${searchQuery}`,
  );

  if (response.status !== 200) {
    throw new Error('Relatório de saques não encontrado');
  }

  return response.data;
};

export const GetDownloadBatchRequest = async (jobRequestId, jobRequestType) => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/batch-request/${jobRequestId}/download?jobRequestType=${jobRequestType}`,
    {
      method: 'GET',
      responseType: 'blob',
    },
  );

  if (response.status !== 200) {
    throw new Error('Não foi possível fazer download');
  }

  const today = new Date();
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    `${today.toLocaleDateString()}_Importacao_Cartao.xlsx`,
  );
  document.body.appendChild(link);
  link.click();

  return response.data;
};

export const GetDownloadExcelImportationModel = async () => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/cards/excel-importation-model/download`,
    {
      method: 'GET',
      responseType: 'blob',
    },
  );

  if (response.status !== 200) {
    throw new Error('Não foi possível fazer download');
  }

  const today = new Date();
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    `${today.toLocaleDateString()}_Modelo_Importacao_Cartao.xlsx`,
  );
  document.body.appendChild(link);
  link.click();

  return response.data;
};

export const GetDownloadExcelImportationModelByTemplate = async template => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/cards/excel-importation-model/${template}/download`,
    {
      method: 'GET',
      responseType: 'blob',
    },
  );

  if (response.status !== 200) {
    throw new Error('Não foi possível fazer download');
  }

  const today = new Date();
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    `${today.toLocaleDateString()}_Modelo_Importacao_Aporte_Multiplo.xlsx`,
  );
  document.body.appendChild(link);
  link.click();

  return response.data;
};

export const postBatchRequest = async data => {
  const response = await api.post(
    `${process.env.REACT_APP_HOST_POSTO}/batch-request`,
    data.dataRequest,
  );

  if (response.status !== 202) {
    throw new Error('Não foi possível enviar a planilha.');
  }

  return response.data;
};

export const getRequestBatchFile = async searchQuery => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/batch-request?${searchQuery}`,
  );

  if (response.status !== 200) {
    throw new Error('Listagem de solicitações não encontrado');
  }

  return response.data;
};

export const GetDownloadExtract = async object => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/account/${object.id}/bank-statement/export?${object.query}`,
    {
      method: 'GET',
      responseType: 'blob', // important
    },
  );

  if (response.status !== 200) {
    throw new Error('Não foi possível fazer download');
  }

  const today = new Date();
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${today.toLocaleDateString()}_Extrato.xlsx`);
  document.body.appendChild(link);
  link.click();

  return response.data;
};

export const PostDownloadReceiptStatementPdf = async object => {
  const response = await api.post(
    `${process.env.REACT_APP_HOST_POSTO}/Account/bank-statement/receipt-pdf`,
    object,
    {
      method: 'GET',
      responseType: 'blob', // important
    },
  );

  if (response.status !== 200) {
    throw new Error('Não foi possível fazer download');
  }

  const url = window.URL.createObjectURL(
    new Blob([response.data], { type: 'application/pdf;base64' }),
  );
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
};

export const GetDownloadReceiptStatementPdf = async value => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/Account/bank-statement/receipts/download-batch-file?idJobRequestStatus=${value}`,
    {
      method: 'GET',
      responseType: 'blob', // important
    },
  );

  if (response.status !== 200) {
    throw new Error('Não foi possível fazer download');
  }

  const url = window.URL.createObjectURL(
    new Blob([response.data], { type: 'application/pdf;base64' }),
  );
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
};

export const GetDownloadReceiptStatementXlsx = async value => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/batch-request/${value}/download?jobRequestType=3`,
    {
      method: 'GET',
      responseType: 'blob', // important
    },
  );

  if (response.status !== 200) {
    throw new Error('Não foi possível fazer download');
  }

  const today = new Date();
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${today.toLocaleDateString()}_Extrato.xlsx`);
  document.body.appendChild(link);
  link.click();
};

export const GetDownloadFileCnab = async action => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/${action}`,
    {
      method: 'GET',
      responseType: 'blob', // important
    },
  );

  if (response.status !== 200) {
    throw new Error('Não foi possível fazer download');
  }

  const today = new Date();
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${today.toLocaleDateString()}_Arquivo.txt`);
  document.body.appendChild(link);
  link.click();

  return response.data;
};

export const GetDepositTransactionsFinanceiro = async searchQuery => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/transactions?${searchQuery}`,
  );

  if (response.status !== 200) {
    throw new Error('Relatório de transação não encontrado');
  }
  return response.data;
};

export const GetDepositTransactionsPDV = async searchQuery => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/operators/mytransactions?${searchQuery}`,
  );

  if (response.status !== 200) {
    throw new Error('Relatório de transação não encontrado');
  }
  return response.data;
};

export const GetCouponsPendent = async searchQuery => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/pdvs/coupons?${searchQuery}`,
  );

  if (response.status !== 200) {
    throw new Error('Cupom não encontrado');
  }

  return response.data;
};

export const PostCouponsPendent = async data => {
  const response = await api.post(
    `${process.env.REACT_APP_HOST_POSTO}/deposit/${data.id}/linked-coupons`,
    data.data,
  );

  if (response.status !== 200) {
    throw new Error('Cupom não encontrado');
  }

  return response.data;
};

export const PostAccount = async data => {
  const response = await api.post(
    `${process.env.REACT_APP_HOST_POSTO}/accountcompanies`,
    data,
  );
  return response.data;
};

export const PostChangeType = async data => {
  const response = await api.patch(
    `${process.env.REACT_APP_HOST_POSTO}/cards/${data.idCard}/change-type`,
    data.data,
  );

  if (response.status !== 200) {
    throw new Error('Troca tipo cartão não encontrado');
  }

  return response.data;
};

export const PostStatementReceiptBatchFile = async (searchQuery, id) => {
  const response = await api.post(
    `${process.env.REACT_APP_HOST_POSTO}/Account/${id}/bank-statement/receipts/request-batch-file?${searchQuery}`,
  );
  return response.data;
};

export const postSolicitacaoExtratoRequest = async (searchQuery, id) => {
  const response = await api.post(
    `${process.env.REACT_APP_HOST_POSTO}/Account/${id}/bank-statement/export/request?${searchQuery}`,
  );
  return response.data;
};

export const getSolicitacaoExtratoRequest = async idJobBatchRequest => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/batch-request?RequestId=${idJobBatchRequest}`,
  );
  return response.data;
};

export const getSolicitacaoExtratoDownload = async idJobBatchRequest => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/batch-request/${idJobBatchRequest}/download?jobRequestType=3`,
  );
  return response.data;
};

export const GetDownloadTransactionsXls = async value => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/transactions/export?${value}`,
    {
      method: 'GET',
      responseType: 'blob', // important
    },
  );

  if (response.status !== 200) {
    throw new Error('Não foi possível fazer download');
  }

  const today = new Date();
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${today.toLocaleDateString()}_Transacao.xlsx`);
  document.body.appendChild(link);
  link.click();

  return response.data;
};
export const GetDownloadTransactionsPostoXls = async (value, id) => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/companies/${id}/transactions/export?${value}`,
    {
      method: 'GET',
      responseType: 'blob', // important
    },
  );

  if (response.status !== 200) {
    throw new Error('Não foi possível fazer download');
  }

  const today = new Date();
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    `${today.toLocaleDateString()}_TransacaoPosto.xlsx`,
  );
  document.body.appendChild(link);
  link.click();

  return response.data;
};

export const GetDownloadTransactionsPDVXls = async value => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/operators/mytransactions/export?${value}`,
    {
      method: 'GET',
      responseType: 'blob', // important
    },
  );

  if (response.status !== 200) {
    throw new Error('Não foi possível fazer download');
  }

  const today = new Date();
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    `${today.toLocaleDateString()}_TransacaoPDV.xlsx`,
  );
  document.body.appendChild(link);
  link.click();

  return response.data;
};

export const GetDownloadCardStatementXlsx = async (value, idCard) => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/cards/${idCard}/card-statement/export?${value}`,
    {
      method: 'GET',
      responseType: 'blob',
    },
  );

  if (response.status !== 200) {
    throw new Error('Não foi possível fazer download');
  }

  const today = new Date();
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    `${today.toLocaleDateString()}_ExtratoDeCartao.xlsx`,
  );
  document.body.appendChild(link);
  link.click();

  return response.data;
};

export const GetDepositTransactionsGerentePosto = async (searchQuery, id) => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/companies/${id}/transactions?${searchQuery}`,
  );

  if (response.status !== 200) {
    throw new Error('Relatório de transação não encontrado');
  }

  return response.data;
};

export const GetDepositTransactionsOperators = async searchQuery => {
  let response = '';

  if (searchQuery) {
    response = await api.get(
      `${process.env.REACT_APP_HOST_POSTO}/operators?companyId=${searchQuery}`,
    );
  } else {
    response = await api.get(`${process.env.REACT_APP_HOST_POSTO}/operators?`);
  }
  if (response.status !== 200) {
    throw new Error('Relatório de transação não encontrado');
  }

  return response.data;
};

export const getDepositReports = async searchQuery => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/DepositFavored?${searchQuery}`,
  );

  if (response.status !== 200) {
    throw new Error('Relatório de saques não encontrado');
  }

  return response.data;
};

export const GetListTransfer = async searchQuery => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/transfer?${searchQuery}&transferStatuses=2&transferStatuses=3`,
  );

  if (response.status !== 200) {
    throw new Error('Transacões não encontrado');
  }
  return response.data;
};

export const getCompaniesRequest = async searchQuery => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_AUTH}/company${
      searchQuery ? `?${searchQuery}` : ''
    }`,
  );

  if (response.status !== 200) {
    throw new Error('Falha ao carregar empresas');
  }
  return response.data.data;
};

export const getUserTokenRequest = async values => {
  const API_URL = `${process.env.REACT_APP_HOST_AUTH}/authentication/login/type`;
  const SUCCESS_STATUS = 200;

  const response = await apiLogin.get(API_URL, {
    headers: {
      login: values,
    },
  });
  if (response.status !== SUCCESS_STATUS) {
    throw new Error(
      `Erro ao validar login de usuário. Status: ${response.status}`,
    );
  }
  return response.data;
};

export const postUserTokenRequest = async data => {
  const response = await apiLogin.post(
    `${process.env.REACT_APP_HOST_AUTH}/authentication/token/check`,
    data,
  );

  if (response.status !== 200) {
    throw new Error('Falha ao cadastrar token');
  }
  return response.data;
};

export const postResendCode = async data => {
  const response = await apiLogin.post(
    `${process.env.REACT_APP_HOST_AUTH}/authentication/resend-token`,
    data,
  );

  if (response.status !== 200) {
    throw new Error('Falha ao enviar token');
  }
  return response.data;
};

export const getCompaniesRequestCnpj = async searchQuery => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_AUTH}/company?NationalRegister=${searchQuery}`,
  );

  if (response.status !== 200) {
    throw new Error('Falha ao carregar empresa');
  }
  return response.data.data;
};

export const getIdTrocoDigital = async searchQuery => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/trocoDigital/${searchQuery}/receipt`,
  );

  if (response.status !== 200) {
    throw new Error('Falha ao carregar troco digital');
  }
  return response.data;
};

export const getIdSaque = async searchQuery => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/withdraw/${searchQuery}/receipt`,
  );

  if (response.status !== 200) {
    throw new Error('Falha ao carregar troco digital');
  }
  return response.data;
};

export const getIdDeposito = async searchQuery => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/deposits/${searchQuery}/receipt`,
  );

  if (response.status !== 200) {
    throw new Error('Falha ao carregar cupom de depósito');
  }
  return response.data;
};

export const postVinculationPeople = async data => {
  const response = await api.post(
    `${process.env.REACT_APP_HOST_POSTO}/account/${data.active}/additionals`,
    data.data,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao vincular pessoa');
  }
  return response.data;
};

export const postVinculationNewPeople = async data => {
  const response = await api.post(
    `${process.env.REACT_APP_HOST_POSTO}/account/${data.active}/individuals/additionals`,
    data.data,
  );
  if (response.status !== 201) {
    throw new Error('Falha ao carregar cadastrar pessoa e vincular');
  }
  return response.data;
};

export const postRegisterLimitCard = async data => {
  const response = await api.post(
    `${process.env.REACT_APP_HOST_POSTO}/cards/${data.id}/limits`,
    data.data,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao cadastrar limite');
  }
  return response.data;
};

export const postChangePasswordCard = async data => {
  const response = await api.put(
    `${process.env.REACT_APP_HOST_POSTO}/cards/${data.id}/password`,
    data.data,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao alterar senha');
  }
  return response.data;
};

export const postVincularNewCardPeople = async data => {
  const response = await api.post(
    `${process.env.REACT_APP_HOST_POSTO}/account/${data.idAccount}/noname-cards`,
    data.data,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao vincular cartão');
  }
  return response.data;
};

export const patchChangeLimitCard = async data => {
  const response = await api.patch(
    `${process.env.REACT_APP_HOST_POSTO}/cards/${data.idCard}/limits/${data.limitId}`,
    data.data,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao alterar limite');
  }
  return response.data;
};

export const postBlockCard = async data => {
  const response = await api.post(
    `${process.env.REACT_APP_HOST_POSTO}/cards/${data}/block`,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao bloquear cartão');
  }
  return response.data;
};

export const postActiveCard = async data => {
  const response = await api.post(
    `${process.env.REACT_APP_HOST_POSTO}/cards/${data.idCard}/unblock`,
    data.data,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao desbloquear cartão');
  }
  return response.data;
};

export const postActiveCardConfig = async data => {
  const response = await api.patch(
    `${process.env.REACT_APP_HOST_POSTO}/cards/${data.idCard}/configs`,
    data.data,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao processar configuração do cartão');
  }

  return response.data;
};

export const postCancelCard = async data => {
  const response = await api.post(
    `${process.env.REACT_APP_HOST_POSTO}/cards/${data.idCard}/cancel?observation=${data.observation}`,
    data.data,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao cancelar cartão');
  }
  return response.data;
};

export const putChangeCardData = async data => {
  const response = await api.put(
    `${process.env.REACT_APP_HOST_POSTO}/cards/${data.idCard}`,
    data.data,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao alterar cartão');
  }
  return response.data;
};

export const putChangeTelephone = async data => {
  const response = await api.put(
    `${process.env.REACT_APP_HOST_POSTO}/cardowners/${data.idPerson}/phone`,
    data.data,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao alterar o telefone');
  }
  return response.data;
};

export const putChangeCardTelephone = async data => {
  const response = await api.put(
    `${process.env.REACT_APP_HOST_POSTO}/cards/${data.idCard}/phone`,
    data.data,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao alterar o telefone');
  }
  return response.data;
};

export const getLimitCard = async data => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/cards/limits?${data}`,
  );
  return response.data;
};

export const postUnbindingPeople = async data => {
  const response = await api.post(
    `${process.env.REACT_APP_HOST_POSTO}/account/${data.idAccount}/additionals/${data.idPerson}/inactivate`,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao desvincular pessoa');
  }
  return response.data;
};

export const postRequestNewCad = async data => {
  const response = await api.post(
    `${process.env.REACT_APP_HOST_POSTO}/Account/${data.company}/noname-cards`,
    data.data,
  );

  return response.data;
};

export const GetListarPessoasVinculada = async id => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/account/${id}/additionals`,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao carregar pessoas vinculadas');
  }
  return response.data;
};

export const GetCheckStatusDocument = async queryParams => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/account/${queryParams.id}/additionals?${queryParams.data}`,
  );
  if (response.status !== 200 && response.status !== 204) {
    throw new Error('Falha ao carregar informações sobre o CPF');
  }
  return response;
};

export const GetListarCartoesVinculado = async search => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/cards/additionals?${search}`,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao carregar cartões vinculados');
  }
  return response.data;
};

export const GetListarCartoesPagamento = async search => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/cards/payments?${search}`,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao carregar cartões de pagamento');
  }
  return response.data;
};

export const PostFazerNovoAporte = async queryParams => {
  const response = await api.post(
    `${process.env.REACT_APP_HOST_POSTO}/cards/${queryParams.idCard}/contributions`,
    queryParams.data,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao fazer aporte do valor');
  }
  return response.data;
};

export const GetCheckPeopleHasCard = async searchQuery => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/cards?${searchQuery}`,
  );
  return response;
};

export const GetShowLimitCardsPeople = async searchQuery => {
  const response = await GetCheckPeopleHasCard(searchQuery);

  if (response.status !== 200) {
    throw new Error('Falha ao carregar limite cadastrado');
  }
  return response.data;
};

export const GetProfissions = async () => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/people/professions`,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao carregar as profissões');
  }
  return response.data;
};

export const GetExtratoCartaoPagamento = async idCard => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/cards/${idCard}/balance`,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao mostrar extrato');
  }
  return response.data;
};

export const GetExtratoCartaoPagamentoBalance = async payload => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/cards/${payload.idCard}/statement?${payload.urlParams}`,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao mostrar extrato');
  }
  return response.data;
};

export const GetExtratoCupom = async idTransaction => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/cards/statement/${idTransaction}/receipt`,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao mostrar extrato');
  }
  return response.data;
};

export const GetStatusCard = async () => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/cards/status`,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao carregar os status dos cartões');
  }
  return response.data;
};

export const GetAccountsCompany = async queryParams => {
  const response = await api.post(
    `${process.env.REACT_APP_HOST_POSTO}/accountcompanies/synchronize?nationalRegister=${queryParams}`,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao carregar as contas da empresa');
  }
  return response.data;
};

export const GetAccountStatus = async idAccount => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/account/${idAccount}/status`,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao obter o status da conta');
  }
  return { idAccount, ...response.data };
};

export const GetCardRequest = async data => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/cards/${data.idCard}?showConfig=${data.showConfig}`,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao carregar os status dos cartões');
  }
  return response.data;
};

export const GetTypeAccountCard = async value => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/accountcompanies?CompanyId=${value}`,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao carregar os status dos cartões');
  }
  return response.data;
};

export const GetCardLimit = async idCard => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/cards/${idCard}/limits`,
  );
  return response.data;
};

export const SetCompanyMainAccount = async accountCompanyId => {
  const response = await api.put(
    `${process.env.REACT_APP_HOST_POSTO}/accountcompanies/${accountCompanyId}/main-account`,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao definir esta conta como principal');
  }
  return response.data;
};

export const PutSetDisplayOnPortal = async accountData => {
  const response = await api.patch(
    `${process.env.REACT_APP_HOST_POSTO}/accountcompanies/${accountData.idAccount}/displayonportal`,
    { displayOnPortal: !accountData.displayOnPortal },
  );

  if (response.status !== 200) {
    throw new Error(
      'Falha ao alterar as configurações de exibição desta conta',
    );
  }
  return true;
};

export const PutDefineAccountAuxiliar = async companyIdAndAccountType => {
  const response = await api.put(
    `${process.env.REACT_APP_HOST_POSTO}/accountcompanies/${companyIdAndAccountType.accountCompanyId}/accounttype`,
    companyIdAndAccountType.data,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao definir esta conta como auxiliar');
  }
  return response.data;
};

export const GetNationalities = async () => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/people/nationalities`,
  );

  if (response.status !== 200) {
    throw new Error('Falha ao carregar nacionalidades');
  }
  return response.data;
};

export const GetMaritalsStatus = async () => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/people/maritals-status`,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao carregar lista de estados civis');
  }
  return response.data;
};

export const GetRelationship = async () => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/people/relationships`,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao carregar lista de relacionamentos');
  }
  return response.data;
};

export const getExtract = async ({ value, values }) => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/account/${
      values.id ? values.id : value.id
    }/bank-statement?${value.newSearchQueryUrl}&fieldsExpand=CouponUrl`,
  );

  if (response.status !== 200) {
    throw new Error('Falha ao carregar os extratos');
  }
  return response.data;
};

export const getPdv = async searchQuery => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/pdvs/${searchQuery}`,
  );

  if (response.status !== 200) {
    throw new Error('Falha ao carregar os extratos');
  }
  return response.data;
};

export const getGrupoEconomico = async searchQuery => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_AUTH}/economicgroups?${searchQuery.value}`,
  );

  if (response.status !== 200) {
    throw new Error('Falha ao carregar os grupo econômico');
  }
  return response.data;
};

export const getBalance = async searchQuery => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/accountcompanies/balance?${searchQuery}&portalvisibility=DisplayOnPortal`,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao carregar os extratos');
  }
  return response.data.data;
};

export const getBalanceSummary = async idAccount => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/cards/balance?idAccount=${idAccount}`,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao carregar os limite de aporte.');
  }
  return response.data;
};

export const getListCardPayment = async searchQuery => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/accountcompanies/?${searchQuery}`,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao carregar cartões de pagamentos');
  }
  return response.data;
};

export const getLimitRuleFilter = async queryString => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/limit_rule?${queryString}`,
  );

  if (response.status !== 200) {
    throw new Error('Falha ao carregar regras');
  }
  return response.data;
};

export const getLimitRule = async searchQuery => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/limit_rule/limit-rule-company?CompanyId=${searchQuery}`,
  );

  if (response.status !== 200) {
    throw new Error('Falha ao carregar regras');
  }
  return response.data.data;
};

export const getLimitType = async searchQuery => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/limit_rule/limit-type`,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao carregar regras');
  }
  return response.data;
};

export const getOperationType = async searchQuery => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/limit_rule/operation-type`,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao carregar regras');
  }
  return response.data;
};

export const getCompaniesActiveRequest = async userId => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_AUTH}/company/${userId}/usercompany`,
  );

  if (response.status !== 200) {
    throw new Error('Falha ao carregar as empresas do usuário');
  }
  return response.data;
};

export const getUsersRequest = async searchQuery => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/User/users${
      searchQuery ? `?${searchQuery}` : ''
    }`,
  );

  if (response.status !== 200) {
    throw new Error('Usuário(s) não encontrado(s)');
  }

  return response.data;
};

export const getUsersFilter = async searchQuery => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/User/users?${searchQuery}`,
  );

  if (response.status !== 200) {
    throw new Error('Usuário(s) não encontrado(s)');
  }

  return response.data;
};

export const postTrocoDigitalRequest = async data => {
  const response = await api.post(
    `${process.env.REACT_APP_HOST_POSTO}/TrocoDigital/schedule`,
    data,
  );

  if (response.status === 202) {
    return response.data;
  }

  if (response.status !== 200) {
    throw new Error('Falha ao registrar Troco Digital');
  }

  return response.data;
};

export const postSaqueRequest = async queryParams => {
  const response = await api.post(
    `${process.env.REACT_APP_HOST_POSTO}/Withdraw/create?${queryParams}`,
  );

  if (response.status === 202) {
    return response.data;
  }

  if (response.status !== 200) {
    throw new Error('Falha ao registrar saque');
  }

  return response.data;
};
export const postUploaFiles = async (
  data,
  nationalRegister,
  username,
  bank,
  agency,
  account,
) => {
  const response = await api.post(
    `${process.env.REACT_APP_HOST_POSTO}/Cnab/upload-cnab?cnpj=${nationalRegister}&username=${username}&bank=${bank}&agency=${agency}&idAccount=${account}`,
    data,
  );

  if (response.status !== 200) {
    throw new Error('Falha ao registrar upload de arquivo CNAB.');
  }
  return response.data;
};

export const getProthuesByCnpjRequest = async cnpj => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/Withdraw/client?cpfcnpj=${cnpj}`,
  );

  if (response.status !== 200 || response.status === 202) {
    throw new Error('Favorecido não encontrado');
  }

  return response.data;
};

export const getCardownersRequest = async numberCard => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/cardowners?numberCard=${numberCard}&checkPhone=true`,
  );

  if (response.status !== 200 || response.status === 202) {
    throw new Error('Favorecido não encontrado');
  }

  return response.data;
};

export const getEconomicGroupsAndProfilesRequest = async () => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_AUTH}/profile/economic-group`,
  );

  if (response.status !== 200) {
    throw new Error('Falha ao trazer os grupos econômicos e as profiles');
  }

  return response.data;
};

export const getProfilesAreasRequest = async searchQuery => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_AUTH}/profile-areas${
      searchQuery ? `?${searchQuery}` : ''
    }`,
  );

  if (response.status !== 200) {
    throw new Error('Falha ao trazer as profiles');
  }

  const data = response.data.map(profileArea => {
    return {
      key: profileArea.id,
      label: profileArea.description,
      value: profileArea.id,
    };
  });

  return data;
};

export const getProfilesRequest = async searchQuery => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_AUTH}/profile${
      searchQuery ? `?${searchQuery}` : ''
    }`,
  );

  if (response.status !== 200) {
    throw new Error('Falha ao trazer as profiles');
  }

  return response.data.data;
};

export const getFunctionalitiesRequest = async () => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_AUTH}/functionality`,
  );

  if (response.status !== 200) {
    throw new Error('Falha ao trazer as funcionalidades');
  }

  return response.data;
};

export const postNewSaque = async data => {
  const response = await api.post(
    `${process.env.REACT_APP_HOST_POSTO}/Withdraw/execute-withdraw`,
    data,
  );

  if (response.status === 202) {
    return response.data;
  }

  if (response.status !== 200 && response.status !== 202) {
    throw new Error(response);
  }

  return response.data;
};

export const postNewTranferencia = async data => {
  const response = await api.post(
    `${process.env.REACT_APP_HOST_POSTO}/transfer`,
    data,
  );

  if (response.status === 202) {
    return response.data;
  }

  if (response.status !== 200 && response.status !== 202) {
    throw new Error(response);
  }

  return response.data;
};

export const patchCancelTransfer = async data => {
  const response = await api.patch(
    `${process.env.REACT_APP_HOST_POSTO}/transfer/${data.transferUuid}/cancel`,
    data.data,
  );

  if (response.status === 202) {
    return response.data;
  }

  if (response.status !== 200 && response.status !== 202) {
    throw new Error(response);
  }

  return response.data;
};

export const postTransferNotification = async value => {
  const response = await api.post(
    `${process.env.REACT_APP_HOST_POSTO}/transfer/${value.transferUuid}/notifications
    `,
  );

  if (response.status === 202) {
    return response.data;
  }

  if (response.status !== 200 && response.status !== 202) {
    throw new Error(response);
  }

  return response.data;
};

export const putNewTranferencia = async (data, transferUuid) => {
  const response = await api.put(
    `${process.env.REACT_APP_HOST_POSTO}/transfer/${transferUuid}`,
    data,
  );
  if (response.status === 202) {
    return response.data;
  }

  if (response.status !== 200 && response.status !== 202) {
    throw new Error(response);
  }

  return response.data;
};

export const getTranferenciaModal = async tranferenciaId => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/transfer/${tranferenciaId}/receipt`,
  );
  if (response.status !== 200) {
    throw new Error('Falha confirmar transferência');
  }
  return response;
};

export const postNewUserRequest = async data => {
  const response = await api.post(
    `${process.env.REACT_APP_HOST_AUTH}/authentication/register`,
    data,
  );

  if (response.status !== 200) {
    throw new Error('Erro ao cadastrar usuário');
  }
  return response.data;
};

export const postNewCompanyRequest = async data => {
  const response = await api.post(
    `${process.env.REACT_APP_HOST_AUTH}/company`,
    data,
  );
  if (response.status !== 201) {
    throw new Error('Falha ao cadastrar nova empresa');
  }

  return response.data;
};

export const postPdvDeposito = async data => {
  const response = await api.post(
    `${process.env.REACT_APP_HOST_POSTO}/deposits`,
    data,
  );
  if (response.status !== 202) {
    throw new Error('Falha ao fazer depósito');
  }
  return response.data;
};

export const postNewLimiteOperations = async data => {
  const response = await api.post(
    `${process.env.REACT_APP_HOST_POSTO}/limit_rule/batch`,
    data,
  );

  if (response.status !== 201) {
    throw new Error('Erro ao cadastrar limite');
  }

  return response.data;
};

export const postNewProfileRequest = async data => {
  const response = await api.post(
    `${process.env.REACT_APP_HOST_AUTH}/profile`,
    data,
  );

  if (response.status !== 201) {
    throw new Error('Falha ao cadastrar nova profile');
  }

  return response.data;
};

export const putUpdateCompanyRequest = async data => {
  const response = await api.put(
    `${process.env.REACT_APP_HOST_AUTH}/company/${data.id}`,
    data,
  );

  if (response.status !== 204) {
    throw new Error('Falha ao atualizar nova empresa');
  }

  return response.data;
};

export const putUpdateActiveCompanyRequest = async (userId, active) => {
  const response = await api.put(
    `${process.env.REACT_APP_HOST_AUTH}/company/active?companyId=${userId}&active=${active}`,
  );

  if (response.status !== 204) {
    throw new Error('Falha ao inativar empresa');
  }

  return response.data;
};

export const putNewDeposito = async data => {
  const response = await api.put(
    `${process.env.REACT_APP_HOST_POSTO}/deposits/confirm`,
    data,
  );

  if (response.status === 202) {
    return response.data;
  }

  if (response.status !== 200) {
    throw new Error('Falha ao registrar depósito');
  }

  return response.data;
};

export const putNewDepositoOld = async data => {
  const response = await api.put(
    `${process.env.REACT_APP_HOST_POSTO}/DepositFavored/confirm-ra-protheus`,
    data,
  );

  if (response.status === 202) {
    return response.data;
  }

  if (response.status !== 200) {
    throw new Error('Falha ao registrar depósito');
  }

  return response.data;
};

export const putTrocoDigital = async data => {
  const response = await api.put(
    `${process.env.REACT_APP_HOST_POSTO}/TrocoDigital/schedule?id=${data}`,
  );

  if (response.status !== 200 && response.status !== 204) {
    throw new Error('Falha o cancelar cnba');
  }

  return response.data;
};

export const putCnab = async data => {
  const response = await api.put(
    `${process.env.REACT_APP_HOST_POSTO}/Cnab/update?cnabId=${data}`,
  );

  if (response.status !== 200) {
    throw new Error('Falha o cancelar cnba');
  }

  return response.data;
};

export const putUpdateUserRequest = async data => {
  const response = await api.put(
    `${process.env.REACT_APP_HOST_AUTH}/user`,
    data,
  );
  if (response.status !== 200) {
    throw new Error('Erro ao atualizar usuário');
  }

  return response.data;
};

export const putUpdateGrupoRequest = async data => {
  const response = await api.put(
    `${process.env.REACT_APP_HOST_AUTH}/economicgroups/${data.id}`,
    data,
  );
  if (response.status !== 200) {
    throw new Error('Erro ao atualizar grupo econômico');
  }

  return response.data;
};

export const putUpdateActiveUserRequest = async (userId, active) => {
  const response = await api.put(
    `${process.env.REACT_APP_HOST_POSTO}/user/active?userId=${userId}&active=${active}`,
  );
  if (response.status !== 204) {
    throw new Error('Erro ao atualizar usuário');
  }

  return response.data;
};

export const putUpdateProfileRequest = async data => {
  const response = await api.put(
    `${process.env.REACT_APP_HOST_AUTH}/profile`,
    data,
  );

  if (response.status !== 204) {
    throw new Error('Erro ao atualizar profile');
  }

  return response.data;
};

export const deleteUserRequest = async userId => {
  const response = await api.delete(
    `${process.env.REACT_APP_HOST_POSTO}/User?userId=${userId}`,
  );

  if (response.status !== 200) {
    throw new Error('Erro ao deletar usuário');
  }

  return response.data;
};

export const deleteCompanyRequest = async (companyName, companyCnpj) => {
  const response = await api.delete(
    `${process.env.REACT_APP_HOST_AUTH}/company?aliasName=${companyName}&regionalRegister=${companyCnpj}`,
  );

  if (response.status !== 204) {
    throw new Error('Falha ao atualizar nova empresa');
  }

  return response.data;
};

export const deleteLimitRule = async ruleId => {
  const response = await api.patch(
    `${process.env.REACT_APP_HOST_POSTO}/limit_rule/${ruleId}/end-limit-rule`,
  );

  if (response.status !== 204) {
    throw new Error('Falha ao deletar regra');
  }

  return response.data;
};

export const deleteProfileRequest = async profileId => {
  const response = await api.delete(
    `${process.env.REACT_APP_HOST_AUTH}/profile?profileId=${profileId}`,
  );

  if (response.status !== 204) {
    throw new Error('Falha ao deletar/inativar profile');
  }

  return response.data;
};

export const getCompanyAccount = async value => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/accountcompanies?CompanyId=${value}`,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao carregar os status dos cartões');
  }
  return response.data;
};

export const getCompanyId = async companyId => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_AUTH}/Company/${companyId}`,
  );
  if (response.status !== 200) {
    throw new Error('Erro ao consultar a empresa');
  }
  return response.data;
};

export const getCompanyUserId = async id => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_POSTO}/User/${id}/companies`,
  );
  if (response.status !== 200) {
    throw new Error('Falha ao carregar lista de empresas');
  }
  return response.data;
};

export const getOfficeHour = async () => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_AUTH}/officehours`,
  );

  if (response.status !== 200) {
    throw new Error('Falha ao cadastrar novo horário de trabalho');
  }

  return response.data;
};

export const postOfficeHour = async data => {
  const response = await api.post(
    `${process.env.REACT_APP_HOST_AUTH}/officehours`,
    data,
  );

  if (response.status !== 201) {
    throw new Error('Falha ao cadastrar novo horário de trabalho');
  }
  return response.data;
};

export const putOfficeHour = async data => {
  const response = await api.put(
    `${process.env.REACT_APP_HOST_AUTH}/officehours/${data.id}`,
    data,
  );

  if (response.status !== 200) {
    throw new Error('Falha ao atualizar novo horário de trabalho');
  }

  return response.data;
};

export const getIdOfficeHour = async id => {
  const response = await api.get(
    `${process.env.REACT_APP_HOST_AUTH}/officehours${id}`,
  );

  if (response.status !== 200) {
    throw new Error('Falha ao cadastrar novo horário de trabalho');
  }

  return response.data;
};

export const deleteOfficeHour = async id => {
  const response = await api.delete(
    `${process.env.REACT_APP_HOST_AUTH}/officehours/${id}`,
  );

  if (response.status !== 200) {
    throw new Error('Falha ao deletar novo horário de trabalho');
  }

  return response.data;
};
